<template>
  <div class="table-content">
    <div class="table-title">
      <div>菜单管理</div>
      <el-button
        v-if="type.indexOf('添加') > -1"
        type="primary"
        @click="handMenuAdd"
        >添 加</el-button
      >
    </div>
    <el-table
      :data="tableData"
      class="menu-table"
      row-key="id"
      border
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        fixed="left"
        prop="menu_name"
        label="菜单名称"
        width="250"
      >
      </el-table-column>
      <el-table-column prop="order" label="排序" width="180" align="center">
      </el-table-column>
      <el-table-column label="类型" width="180" align="center">
        <template #default="scope">
          <div>{{ scope.row.leixing == 1 ? "菜单" : "按钮" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="路径" width="250" align="center">
        <template #default="scope">
          <div v-if="scope.row.leixing == 1">{{ scope.row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="150" align="center">
        <template #default="scope">
          <div
            v-if="type.indexOf('启用/禁用') > -1 && scope.row.leixing == 1"
            @click="handSwitch(scope.row.id, scope.row.status)"
          >
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </div>
          <div v-else-if="scope.row.leixing == 1">
            {{ scope.row.status == 1 ? "启用" : "禁用" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        min-width="200"
        show-overflow-tooltip
        align="center"
      >
        <template #default="scope">
          <el-button
            v-if="type.indexOf('添加') > -1 && scope.row.leixing == 1"
            @click="handleAdd(scope.row.id)"
            type="text"
            size="small"
            >添加</el-button
          >
          <el-button
            v-if="type.indexOf('编辑') > -1"
            @click="handleClick(scope.row.id)"
            type="text"
            size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      v-model="dialogFormVisible"
      width="500px"
      @close="handClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-form"
      >
        <el-form-item label="类型：" prop="leixing">
          <el-radio-group v-model="form.leixing">
            <el-radio label="1">菜单</el-radio>
            <el-radio label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="中文名称：" prop="menu_name">
          <el-input v-model="form.menu_name"></el-input>
        </el-form-item>
        <el-form-item label="英文名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="路径：" prop="function">
          <el-input v-model="form.function"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="order">
          <el-input v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item label="图标：" prop="icon">
          <el-input v-model="form.icon"></el-input>
          <div>https://element-plus.gitee.io/#/zh-CN/component/icon</div>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">保存</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMenuList,
  addMenu,
  updateMenu,
  enableDisable,
  getMenuInfo,
} from "@/api/apiList/system-api";

export default {
  data() {
    return {
      tableData: [],
      loading: true,
      type: "",
      dialogFormVisible: false,
      title: "添加菜单",
      form: {
        id: "",
        parent_id: 0,
        leixing: "",
        menu_name: "",
        name: "",
        function: "",
        order: "",
        icon: "",
        status: 1,
      },
      rules: {
        leixing: [{ required: true, message: "请选择类型", trigger: "change" }],
        menu_name: [
          { required: true, message: "请输入中文名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入英文名称", trigger: "blur" }],
        function: [{ required: true, message: "请输入路径", trigger: "blur" }],
        order: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
    };
  },
  methods: {
    handClose() {
      this.form = {
        id: "",
        parent_id: 0,
        leixing: "",
        menu_name: "",
        name: "",
        function: "",
        order: "",
        icon: "",
        status: 1,
      };
      this.resetForm("form");
    },
    handMenuAdd() {
      this.title = "添加菜单";
      this.dialogFormVisible = true;
    },
    handleAdd(e) {
      this.form.parent_id = parseInt(e);
      this.title = "添加菜单";
      this.dialogFormVisible = true;
    },
    handleClick(e) {
      let obj = {
        id: e,
      };
      getMenuInfo(obj).then((res) => {
        if (res.result === "200") {
          this.form.id = e;
          this.form.parent_id = res.data.parent_id;
          this.form.leixing = res.data.leixing;
          this.form.menu_name = res.data.menu_name;
          this.form.name = res.data.name;
          this.form.function = res.data.function;
          this.form.order = res.data.order;
          this.form.icon = res.data.icon;
          this.form.status = res.data.status;
          this.title = "编辑菜单";
          this.dialogFormVisible = true;
        }
      });
    },
    handSwitch(id, e) {
      let str = e == 1 ? "启用" : "禁用";
      this.$confirm("确定要" + str + "此菜单或权限?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: id,
            status: e,
          };
          enableDisable(obj).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.data.info,
              });
              this.handList();
            }
          });
        })
        .catch(() => {
          this.handList();
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title === "添加菜单") {
            addMenu(this.form).then((res) => {
              if (res.result === "200") {
                this.dialogFormVisible = false;
                this.$message.success({
                  message: res.data.info,
                  type: "success",
                });
                this.handList();
              }
            });
          } else {
            updateMenu(this.form).then((res) => {
              if (res.result === "200") {
                this.dialogFormVisible = false;
                this.$message.success({
                  message: res.data.info,
                  type: "success",
                });
                this.handList();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handList() {
      getMenuList().then((res) => {
        if (res.result === "200") {
          this.tableData = res.data;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    this.handList();
  },
};
</script>

<style lang="scss">
.menu-table {
  width: 100%;
  margin-top: 20px;
}
</style>
